<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <InstanceSettings />
      </v-col>
      <v-col cols="12" md="8">
        <SensorSettings />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InstanceSettings from "../components/admin/InstanceSettings";
import SensorSettings from "../components/admin/SensorSettings";
export default {
  name: "Admin",
  components: { SensorSettings, InstanceSettings },
  data: function() {
    return {};
  },
  watch: {},
  created: function() {},
  computed: {},
  methods: {}
};
</script>

<style scoped></style>
