<template>
  <v-container v-if="sites.length > 0" class="ma-0 pa-0">
    <MaterialCardMini v-for="site in sites" :key="site.id">
      <template v-slot:heading>
        <v-card-title class="pa-0 pl-2" style="font-size:.85em;">
          {{ site.name }}
        </v-card-title>
      </template>
      <v-card-text class="ma-0 pa-0">
        <v-alert outlined color="primary" class="ma-2 mt-4 pa-6 mb-0" text>
          <v-row>
            <span style="font-size:.8em">Site Settings</span>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="pa-0">
              <v-checkbox
                v-model="site.default_include_public"
                label="Monitor Public URLs"
                dense
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6" class="pa-0">
              <v-checkbox
                v-model="site.default_include_private"
                label="Monitor Private URLs"
                dense
                hide-details
              />
            </v-col>
          </v-row>
        </v-alert>

        <v-data-table
          :headers="sensor_headers"
          :items="site.sensors"
          class="elevation-0 ma-0"
          :hide-default-footer="site.sensors.length < 6"
        >
          <template v-slot:item.name="props">
            <v-btn
              text
              color="secondary"
              style="text-transform: none !important;"
              >{{ props.item.name }}</v-btn
            >
          </template>
          <template v-slot:item.http="props">
            <v-simple-checkbox
              v-model="props.item.http"
              dense
              hide-details
              class="ma-0 pa-0"
            />
          </template>
          <template v-slot:item.ldap="props">
            <v-simple-checkbox v-model="props.item.ldap" dense hide-details />
          </template>

          <template v-slot:item.ocsp="props">
            <v-simple-checkbox v-model="props.item.ocsp" dense hide-details />
          </template>

          <template v-slot:item.ct="props">
            <v-simple-checkbox v-model="props.item.ct" dense hide-details />
          </template>

          <template v-slot:item.dns="props">
            <v-simple-checkbox v-model="props.item.dns" dense hide-details />
          </template>
          <template v-slot:item.ad="props">
            <v-simple-checkbox v-model="props.item.ad" dense hide-details />
          </template>

          <template v-slot:item.uri_count="props">
            <v-btn outlined color="primary" small>
              {{ props.item.uri_count }} URLs
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(site, item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(site, item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title style="font-size:1.2em;"
                >Site Sensors</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="success" small v-bind="attrs" v-on="on">
                    <v-icon small>mdi-plus</v-icon> Add Sensor
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.name"
                            label="Dessert name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.calories"
                            label="Calories"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.fat"
                            label="Fat (g)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.carbs"
                            label="Carbs (g)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.protein"
                            label="Protein (g)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card-text>
    </MaterialCardMini>
  </v-container>
</template>

<script>
import MaterialCardMini from "../charts/MaterialCardMini";
import UserService from "../../store/UserService";
export default {
  name: "SensorSettings",
  components: { MaterialCardMini },
  data: function() {
    return {
      dialog: false,
      dialogDelete: false,
      loading: true,
      sites: [],
      sensor_headers: [
        {
          text: "Name",
          sortable: false,
          value: "name",
          align: "center"
        },
        {
          text: "http",
          sortable: false,
          value: "http",
          align: "center"
        },
        {
          text: "ldap",
          sortable: false,
          value: "ldap",
          align: "center"
        },
        {
          text: "ocsp",
          sortable: false,
          value: "ocsp",
          align: "center"
        },
        /*{
          text: "ct",
          sortable: false,
          value: "ct",
          align: "center"
        },
        {
          text: "dns",
          sortable: false,
          value: "dns",
          align: "center"
        },*/
        {
          text: "ad",
          sortable: false,
          value: "ad",
          align: "center"
        },
        {
          text: "URLs",
          sortable: false,
          value: "uri_count",
          align: "center"
        },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        http: false,
        ldap: false,
        ct: false,
        dns: false,
        ocsp: false,
        ad: false
      },
      defaultItem: {
        name: "",
        http: false,
        ldap: false,
        ct: false,
        dns: false,
        ocsp: false,
        ad: false
      }
    };
  },
  computed: {
    this_instance: function() {
      return this.$store.getters.instance;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Sensor" : "Edit Sensor";
    }
  },
  mounted() {
    this.get_sensors();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  created() {},
  methods: {
    get_sensors: function() {
      UserService.getLocalApi("sites").then(response => {
        this.sites = response.data.results;
        console.log(this.sites);
        this.loading = false;
      });
    },
    editItem(site, item) {
      this.editedIndex = site.sensors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(site, item) {
      this.editedIndex = site.sensors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.sites.sensors.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.sites.sensors[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    }
  }
};
</script>

<style scoped></style>
