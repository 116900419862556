<template>
  <MaterialCardMini color="secondary">
    <template v-slot:heading>
      <v-card-title class="pa-0 pl-2" style="font-size:.85em;">
        Instance Settings
      </v-card-title>
    </template>
    <v-card-text class="ma-0 pa-0">
      <v-card class="pa-2 mt-4">
        <v-form readonly>
          <v-text-field
            v-model="this_instance.name"
            label="Instance Name"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="this_instance.api_url"
            label="API URL"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="this_instance.socket_url"
            label="Websocket URL"
            outlined
            dense
          ></v-text-field>
          <v-checkbox
            v-model="this_instance.private"
            label="Private Instance"
            outlined
            dense
          ></v-checkbox>
          <v-text-field
            v-model="this_instance.public_api_url"
            label="Public API URL"
            outlined
            dense
          ></v-text-field>
        </v-form>
      </v-card>
    </v-card-text>
  </MaterialCardMini>
</template>

<script>
import MaterialCardMini from "../charts/MaterialCardMini";
export default {
  name: "InstanceSettings",
  components: { MaterialCardMini },
  computed: {
    this_instance: function() {
      return this.$store.getters.instance;
    }
  }
};
</script>

<style scoped></style>
